type FlashMessage = {
  type: "danger" | "warning" | "success";
  message: string;
  durationMs?: number;
};

export const useFlashMessage = () => {
  const flash = useState<FlashMessage | null>("flash-message", () => null);
  return flash;
};

export const setFlashMessage = (flashMessage: FlashMessage) => {
  if (import.meta.browser) {
    const flash = useFlashMessage();
    flash.value = flashMessage;

    if (flashMessage.durationMs) setTimeout(() => (flash.value = null), flashMessage.durationMs);
  } else {
    const flashCookie = useCookie("flash-message");
    flashCookie.value = JSON.stringify(flashMessage);
  }
};
